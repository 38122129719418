




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Work } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';

import WorksComponent from '@/components/WorksComponent.vue'
import BaseButton from '@/components/BaseButton.vue'
@Component<Works>({
  components: {
    WorksComponent,
    BaseButton,
  },
  head: {
    title() { return { inner: this.title } },
    meta() {
      return [
        { property: 'og:url', content: 'https://www.iekichi.com/', id:'ogurl' },
        { property: 'og:title', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: '住まいをつくる、お店をつくる、農作物をつくる。様々な「つくる人たち」と、ゆるやかにつながり支えあいながら、建築を通して「心地よい暮らし」を提案しています。｜一級建築設計事務所 家吉建築デザイン', id: 'ogdescription'},
        { property: 'og:site_name', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id: 'ogsitename' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
      ];
    },
  }
})
export default class Works extends Vue {
  private title='';
  private titles: { [key: string]: string } = {
    'house': '住まい',
    'facility': '施設',
    'furniture': '家具',
    'shop': 'お店',
  }
  private isLoaded = true;

  mounted() {
    this.getData();
  }

  private totalPages = 1;
  private get currentPage(){
    return parseInt(this.$route.query.page as string) || 1
  }

  @Watch('$route')
  private async getData(){
    this.isLoaded = false;
    let path = `posts?_embed&per_page=20`;
    if(this.$route.params.id){
      await ApiHelper.callApi(`tags/${this.$route.params.id}`, 'get', {}, res => {
        this.title = res.data.name;
      });
      path = `${path}&tags=${this.$route.params.id}`;
    }else{
      this.title = this.titles[this.$route.params.slug] || 'すべての建築';
    }
    if(this.$route.query.page){
      path = `${path}&page=${this.$route.query.page}`;
    }
    const slug = this.$route.params.slug || 'works';
    await ApiHelper.callApi(`categories?slug=${slug}`, 'get', {}, res => {
      path = `${path}&categories=${res.data[0].id}`;
    });
    ApiHelper.callApi(path, 'get', {}, res => {
      this.totalPages = res.headers["x-wp-totalpages"];
      AppModule.setWorks(res.data.map((v: any)=>{
        return {
          id: v.id,
          slug: v.slug,
          title: v.title.rendered,
          thumbnail: v._embedded['wp:featuredmedia'] ? v._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : undefined,
          imageUrl: v._embedded['wp:featuredmedia'] ? v._embedded['wp:featuredmedia'][0].source_url : undefined,
        } as Work;
      }));
      this.isLoaded = true;
      this.$emit('updateHead');
    });
  }

}
